import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from './components/loading.js';

// Используем React.lazy для динамического импорта страниц
const MainPage = lazy(() => import('./pages/mainPage'));
const AuthPage = lazy(() => import('./pages/authPage.js'));
const LessonsPage = lazy(() => import('./pages/lessonsPage'));
const CreatingPage = lazy(() => import('./pages/creatingPage'));
const LessonManagementPage = lazy(() => import('./pages/lessonManagementPage'));
const ArchivePage = lazy(() => import('./pages/archivePage'));
const ProfilePage = lazy(() => import('./pages/profilePage'));
const TeachersPage = lazy(() => import('./pages/teachers'));
const HourInfo = lazy(() => import('./pages/hourInfoPage'));
const RewiewPage = lazy(() => import('./pages/reviewPage/index'));
const AnalyticsPage = lazy(() => import('./pages/analyticsPage'));
const IncomesPage = lazy(() => import('./pages/incomesPage'));
const BalancePage = lazy(() => import('./pages/balancePage/index.js'));
const DiskPage = lazy(() => import('./pages/diskPage'));
const TeacherHourPage = lazy(() => import('./pages/teachersHoursPage.js'));
const ReviewDebtors = lazy(() => import('./pages/reviewDebtorsPage.js'));
const SchedulePage = lazy(() => import('./pages/schedulePage.js'));
const ManualMoneyAdd = lazy(() => import('./pages/manualMoneyAdd.js'));
const Payroll = lazy(() => import('./pages/payrollPage.js'));
const CreateTransfertsPage = lazy(() => import('./pages/createTransfertsPage.js'));

const Routes = () => {
  const currentUserState = useSelector(state => state.currentUser);
  const isLead = currentUserState?.currentUser?.role === 'teamlead';
  const isAdmin = currentUserState?.currentUser?.isAdmin;
  const isLoggedIn = currentUserState.isLoggedIn;

  return (
    // Добавляем Suspense с fallback-компонентом для отображения во время загрузки
    <Suspense fallback={<div className='container'><Loading /></div>}>
      <Switch>
        <Route path="/" component={MainPage} exact />

        {isLoggedIn && <Route path="/profile" component={ProfilePage} />}
        {isLoggedIn && <Route path="/reviews" component={RewiewPage} />}
        {isLoggedIn && <Route path="/lessons" component={LessonsPage} /> }
        {isLoggedIn && <Route path="/disk" component={DiskPage} /> }
        
        {isAdmin && <Route path="/lessons-check" component={LessonManagementPage} />}
        {isAdmin && <Route path="/archive" component={ArchivePage} />}
        {isAdmin && <Route path="/hour-info" component={HourInfo} />}    
        {isAdmin && <Route path="/incomes" component={IncomesPage} />}    
        {(isAdmin || isLead) && <Route path="/creation" component={CreatingPage} />}
        {(isAdmin || isLead) && <Route path="/teachers" component={TeachersPage} />}
        {(isAdmin || isLead) && <Route path="/review-debtors" component={ReviewDebtors} />}
        {(isAdmin || isLead) && <Route path="/schedule" component={SchedulePage} />}
        {isAdmin && <Route path="/anltcs" component={AnalyticsPage} />}
        {isAdmin && <Route path="/balances" component={BalancePage} />}
        {isAdmin && <Route path="/teachers-hours/:id/:month" component={TeacherHourPage} />}
        {isAdmin && <Route path="/manual-money" component={ManualMoneyAdd} />}
        {isAdmin && <Route path="/payroll" component={Payroll} />}
        {isAdmin && <Route path="/create-transferts" component={CreateTransfertsPage} />}
        
        {!isLoggedIn && <Route path="/login" component={AuthPage} />}

        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
